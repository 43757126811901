<template>
    <div id="auth">

        <div class="container">
            <div class="row">
                <div class="col-md-5 col-sm-12 mx-auto">
                    <div class="card pt-4">
                        <div class="card-body">
                            <div class="text-center mb-5">
                                <img src="@/assets/images/sun.png" height="128" class='mb-4'>
                                <h3>晴鈦朗光電</h3>
                            </div>

                            <div class="form-group position-relative has-icon-left">
                                <label for="LoginProvider">帳號</label>
                                <div class="position-relative">

                                    <input type="text" v-model="model.LoginProvider" name="LoginProvider"
                                        id="LoginProvider" class="form-control" required autocomplete="off" />

                                    <div class="form-control-icon">
                                        <i data-feather="user"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group position-relative has-icon-left">
                                <div class="clearfix">
                                    <label for="ProviderKey">密碼</label>
                                </div>
                                <div class="position-relative">
                                    <input v-model="model.ProviderKey" class="form-control" type="password"
                                        autocomplete="off" name="ProviderKey" id="ProviderKey" required />

                                    <div class="form-control-icon">
                                        <i data-feather="lock"></i>
                                    </div>
                                </div>
                            </div>

                            <div v-if="qrCodeUrl != ''">
                                <qrcode-vue :value="qrCodeUrl" :size="200"></qrcode-vue>

                            </div>

                            <div class="form-group position-relative">
                                <label for="google2FaCode">google驗證碼</label>
                                <div class="position-relative">
                                    <input type="text" name="google2FaCode" v-model="model.google2FaCode"
                                        class="form-control" required autocomplete="off" />
                                </div>
                            </div>

                            <div class="clearfix">
                                <button @click="handleLogin()" class="btn btn-primary float-end">登錄</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Swal from 'sweetalert2'
import QrcodeVue from 'qrcode.vue';
import { login } from '@/api/Authentication.js'
import { enable2fa } from '@/api/Authentication.js'

export default {
    components: { QrcodeVue },
    data() {
        return {
            model: {
                LoginProvider: '',
                ProviderKey: '',
                google2FaCode: '',
            },
            isSaving: false,
            qrCodeUrl: '',
            hasGoogle2Fa: false,
        };
    },
    mounted() {
        this.model.LoginProvider = '';
        this.model.ProviderKey = '',
        this.model.google2FaCode = '',
        console.log(this.qrCodeUrl);
    },
    methods: {
        getRequest() {
            var result =
            {
                "LoginProvider": this.model.LoginProvider,
                "ProviderKey": btoa(this.model.ProviderKey),
                "google2FaCode": this.model.google2FaCode,
            };

            return result;
        },
        check2Fa() {
            enable2fa().then(response => {
                const secret = response.data.secret;
                this.qrCodeUrl = secret;
            });
        },
        handleLogin() {
            if (this.model.LoginProvider == '' || this.model.ProviderKey == '') {
                return;
            }
            else {
                this.isSaving = true


                login(this.getRequest()).then(rep => {

                    //alert(JSON.stringify(rep));
                    if (rep.code == 204) {
                        localStorage.setItem('token', rep.data);
                        this.$router.push({ name: "admin" });
                    }
                    else if (rep.code == 4011) {
                        this.qrCodeUrl = rep.data.qrCodeUrl;
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: rep.message,
                            showConfirmButton: false,
                            timer: 1500
                        });
                    }

                })
                    .catch(error => {

                        this.isSaving = false;

                        Swal.fire({
                            icon: 'error',
                            title: error,
                            showConfirmButton: false,
                            timer: 1500
                        });

                        return error
                    });
            }
        }
    }
}
</script>

<style>
#auth {
    background: #6c757d;
    min-height: 100vh;
    padding-top: 80px;
    background-image: url(/images/background/auth.jpg)
}

#auth .card {
    box-shadow: 0 5px 15px rgba(36, 104, 146, .6);
    border: none
}
</style>
